import React from 'react';

import {FontVariant} from '../../../../design-tokens/constants';
import {withColorScheme} from '../../../themes/ThemeContext';
import {Button} from '../../elements/Button/Button';
import {Text} from '../../elements/Text/Text';
import {Cell} from '../../foundation/Grid/Cell/Cell';
import {Grid} from '../../foundation/Grid/Grid';
import {List} from '../List/List';
import {Modal} from '../Modal/Modal';
import type {ModalProps} from '../Modal/Modal';

const ModalLight = withColorScheme(Modal, 'light');

export const ConfirmationModal: React.FC<
    ModalProps & {
        cancelButtonText?: string;
        cancelButtonTestid?: string;
        confirmButtonText?: string;
        confirmButtonTestid?: string;
        description?: string;
        isOpen: boolean;
        onCancel: () => void;
        onConfirm: () => void;
        title?: string;
    }
> = ({
    cancelButtonText,
    cancelButtonTestid,
    confirmButtonText,
    confirmButtonTestid,
    description,
    isOpen,
    onCancel,
    onConfirm,
    title,
    ...props
}) => (
    <ModalLight isOpen={isOpen} {...props}>
        <List spacing="none">
            <Text fontVariant={FontVariant.H5} className="mb-2">
                {title}
            </Text>
            {description && (
                <Text fontVariant={FontVariant.Body} className="mb-4">
                    {description}
                </Text>
            )}
            <Grid className="mt-2">
                <Cell xs={6}>
                    <Button
                        onClick={onCancel}
                        variant="tertiary"
                        size="medium"
                        data-testid={cancelButtonTestid}
                        modifier="fullWidth"
                    >
                        {cancelButtonText}
                    </Button>
                </Cell>
                <Cell xs={6}>
                    <Button
                        onClick={onConfirm}
                        variant="primary"
                        size="medium"
                        data-testid={confirmButtonTestid}
                        modifier="fullWidth"
                    >
                        {confirmButtonText}
                    </Button>
                </Cell>
            </Grid>
        </List>
    </ModalLight>
);
