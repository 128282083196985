import React, {useEffect, useRef} from 'react';

import {FontVariant} from '../../../../design-tokens/constants';
import {isRefObject} from '../../../utils/typeGuards';
import {CenterLayout} from '../../foundation/CenterLayout/CenterLayout';
import {FullSizeWindow} from '../../foundation/FullSizeWindow/FullSizeWindow';
import {Text} from '../Text/Text';

import type {VideoElement} from './Video';

export const ForwardRefVideo = React.forwardRef<
    VideoElement,
    React.ComponentProps<'video'> & {
        srcObject?: MediaStream;
        sinkId?: string;
        captionsSrc?: string;
        captionsSrcLang?: string;
        autoPlay?: boolean;
        playsInline?: boolean;
        textTrackKind?: 'captions' | 'subtitles';
    }
>(
    (
        {
            srcObject,
            sinkId,
            captionsSrc,
            captionsSrcLang,
            autoPlay = true,
            playsInline = true,
            textTrackKind,
            ...props
        },
        ref,
    ) => {
        let videoRef = useRef<VideoElement>(null);

        useEffect(() => {
            if (videoRef.current && srcObject) {
                videoRef.current.srcObject = srcObject;
            }
        }, [srcObject, videoRef]);

        useEffect(() => {
            if (
                (videoRef?.current?.srcObject as MediaStream)?.getAudioTracks()
                    .length
            ) {
                videoRef?.current?.setSinkId?.(sinkId || '');
            }
        }, [sinkId, videoRef]);

        if (!isRefObject(ref)) {
            return (
                <FullSizeWindow>
                    <CenterLayout>
                        <Text fontVariant={FontVariant.H1}>
                            <a href="https://reactjs.org/docs/refs-and-the-dom.html#dont-overuse-refs">
                                Use `createRef()/useRef()` with React {'>='}{' '}
                                16.3
                            </a>
                        </Text>
                    </CenterLayout>
                </FullSizeWindow>
            );
        }

        videoRef = ref as React.RefObject<VideoElement>;
        return (
            <video
                ref={videoRef}
                autoPlay={autoPlay}
                playsInline={playsInline}
                {...props}
            >
                {textTrackKind && (
                    <track
                        kind={textTrackKind}
                        src={captionsSrc}
                        srcLang={captionsSrcLang}
                        default
                    />
                )}
            </video>
        );
    },
);

ForwardRefVideo.displayName = 'Video';
