/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface RequestTokenMap {
    '200': Status200;
    '403': Status403;
    '404': Status404;
    '415': Status415;
    '502': string;
    '504': string;
    '529': string;
    Body: TopLevel;
}
export interface Status200 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success';
    result: Result;
}
/**
 * This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.
 */
export interface Result {
    allow_1080p?: boolean;
    /**
     * Max bandwidth-in for the conference
     */
    bandwidth_in?: number;
    /**
     * Max bandwidth-out for the conference
     */
    bandwidth_out?: number;
    /**
     * An optional call tag that is assigned to this participant.
     */
    call_tag?: string;
    /**
     * Type of the call.
     */
    call_type?: 'video' | 'video-only' | 'audio';
    /**
     * Name of the conference
     */
    conference_name?: string;
    /**
     * The authentication token for future requests.
     */
    token: string;
    /**
     * Validity lifetime in seconds. Use refresh_token to obtain an updated token.
     */
    expires: string;
    fecc_enabled?: boolean;
    guests_can_present?: boolean;
    idp_uuid?: string;
    /**
     * The uuid associated with this newly created participant. It is used to identify this participant in the participant list.
     */
    participant_uuid: string;
    version: Version;
    /**
     * Whether the participant is connecting as a `HOST` or a `GUEST`.
     */
    role: 'HOST' | 'GUEST';
    route_via_registrar?: boolean;
    rtmp_enabled?: boolean;
    rtsp_enabled?: boolean;
    trickle_ice_enabled?: boolean;
    vp9_enabled?: boolean;
    /**
     * true = chat is enabled; false = chat is not enabled.
     */
    chat_enabled?: boolean;
    /**
     * Either `conference`, `gateway` or `test_call` depending on whether this is a VMR, gateway or Test Call Service respectively.
     */
    service_type?:
        | 'conference'
        | 'gateway'
        | 'test_call'
        | 'lecture'
        | 'media_playback';
    /**
     * STUN server configuration from the Pexip Conferencing Node.
     */
    stun?: Stun[];
    /**
     * TURN server configuration from the Pexip Conferencing Node.
     */
    turn?: Turn[];
    /**
     * Echoes the display name in the request.
     */
    display_name: string;
    /**
     * Whether the Automatically send deployment and usage statistics to Pexip global setting has been enabled on the Pexip installation.
     */
    analytics_enabled?: boolean;
    /**
     * The service type this user is connecting into. May be `conference`, `gateway` or `test_call` as for service_type if directly connecting in. May also be `waiting_room` if waiting to be allowed to join a locked conference, or `ivr` if on the PIN entry screen.
     */
    current_service_type:
        | 'conference'
        | 'gateway'
        | 'test_call'
        | 'waiting_room'
        | 'ivr'
        | 'lecture'
        | 'media_playback';
    /**
     * Reflects if vmr uses direct media.
     */
    direct_media?: boolean;
    service_name?: string;
    pex_datachannel_id?: number;
    client_stats_update_interval?: number;
    use_relay_candidates_only?: boolean;
}
/**
 * This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.
 */
export interface Version {
    version_id?: string;
    pseudo_version?: string;
}
/**
 * STUN server configuration from the Pexip Conferencing Node.
 */
export interface Stun {
    url: string;
}
/**
 * TURN server configuration from the Pexip Conferencing Node.
 */
export interface Turn {
    credential?: string;
    urls: string[];
    username?: string;
}
export interface Status403 {
    /**
     * Status of the response, eg. success.
     */
    status: 'success' | 'failed';
    result:
        | Result1
        | Result2
        | ResultRedirect
        | ResultExtension
        | (
              | '404 Not Found'
              | '502 Bad Gateway'
              | '503 Service Unavailable'
              | 'All conference hosts departed hosted conference'
              | 'API initiated conference termination'
              | 'API initiated participant disconnect'
              | 'Call disconnected'
              | 'Call rejected'
              | 'Conference extension not found'
              | 'Conference host ended the conference with a DTMF command'
              | 'Conference terminated by an administrator'
              | 'Conference terminated by another participant'
              | 'Disabled'
              | 'Disconnected by an administrator'
              | 'Disconnected by another participant'
              | 'Failed to forward request'
              | 'Gateway dial out failed'
              | 'Ice failure'
              | 'Insufficient licenses'
              | 'invalid gateway routing rule transform'
              | 'Invalid license'
              | 'Invalid PIN'
              | 'Invalid protocol for service'
              | 'Invalid role'
              | 'Invalid token'
              | 'Last remaining participant removed from conference after timeout'
              | 'Media node disconnected'
              | 'Media process disconnected'
              | 'Neither conference nor gateway found'
              | 'No direct route between Edge and Transcoding'
              | 'No participants can keep conference alive'
              | 'Out of proxying resource'
              | 'Out of resource'
              | 'Out of transcoding resource'
              | 'Participant exceeded PIN entry retries'
              | 'Participant limit reached'
              | 'Proxied participant disconnected'
              | 'Resource unavailable'
              | 'Signaling node disconnected'
              | 'SSO Authentication Failed. SSO is not available from this domain'
              | 'SSO Authentication Failed. The system is in Maintenance mode'
              | 'SSO Authentication Failed'
              | 'SSO enabled but no Identity Providers configured'
              | 'System in maintenance mode'
              | 'Telehealth Call failure'
              | 'Test call finished'
              | 'Timeout waiting for conference host to join or permit access to locked conference'
              | 'Timer expired awaiting token refresh'
              | 'transfer failed'
              | 'Unexpected Response: 503'
          );
}
/**
 * If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.
 */
export interface Result1 {
    conference_extension?: 'standard' | 'mssip';
    /**
     * Guest pin
     */
    guest_pin: 'required' | 'none';
    /**
     * Host pin
     */
    pin: 'required' | 'none';
    version: Version1;
}
/**
 * This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.
 */
export interface Version1 {
    version_id?: string;
    pseudo_version?: string;
}
/**
 * If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.
 */
export interface Result2 {
    /**
     * Idp providers
     */
    idp: Idp[];
    version: Version1;
}
export interface Idp {
    name: string;
    img?: string;
    uuid: string;
}
/**
 * If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.
 */
export interface ResultRedirect {
    /**
     * `<IdP's SSO URL>?SAMLRequest=<Base64 encoded SAML AuthNRequest message>`
     */
    redirect_url: string;
    redirect_idp: RedirectIdp;
    version: Version1;
}
export interface RedirectIdp {
    name: string;
    uuid: string;
}
export interface ResultExtension {
    conference_extension: 'required';
    conference_extension_type: 'mssip' | 'standard';
}
export interface Status404 {
    /**
     * Status of the response, eg. success.
     */
    status: 'failed';
    /**
     * Error string
     */
    result:
        | 'Neither conference nor gateway found'
        | 'Conference extension not found';
}
export interface Status415 {
    /**
     * Status of the response, eg. failed.
     */
    status: 'failed';
    /**
     * Error strings
     */
    result:
        | '404 Not Found'
        | '502 Bad Gateway'
        | '503 Service Unavailable'
        | 'All conference hosts departed hosted conference'
        | 'API initiated conference termination'
        | 'API initiated participant disconnect'
        | 'Call disconnected'
        | 'Call rejected'
        | 'Conference extension not found'
        | 'Conference host ended the conference with a DTMF command'
        | 'Conference terminated by an administrator'
        | 'Conference terminated by another participant'
        | 'Disabled'
        | 'Disconnected by an administrator'
        | 'Disconnected by another participant'
        | 'Failed to forward request'
        | 'Gateway dial out failed'
        | 'Ice failure'
        | 'Insufficient licenses'
        | 'invalid gateway routing rule transform'
        | 'Invalid license'
        | 'Invalid PIN'
        | 'Invalid protocol for service'
        | 'Invalid role'
        | 'Invalid token'
        | 'Last remaining participant removed from conference after timeout'
        | 'Media node disconnected'
        | 'Media process disconnected'
        | 'Neither conference nor gateway found'
        | 'No direct route between Edge and Transcoding'
        | 'No participants can keep conference alive'
        | 'Out of proxying resource'
        | 'Out of resource'
        | 'Out of transcoding resource'
        | 'Participant exceeded PIN entry retries'
        | 'Participant limit reached'
        | 'Proxied participant disconnected'
        | 'Resource unavailable'
        | 'Signaling node disconnected'
        | 'SSO Authentication Failed. SSO is not available from this domain'
        | 'SSO Authentication Failed. The system is in Maintenance mode'
        | 'SSO Authentication Failed'
        | 'SSO enabled but no Identity Providers configured'
        | 'System in maintenance mode'
        | 'Telehealth Call failure'
        | 'Test call finished'
        | 'Timeout waiting for conference host to join or permit access to locked conference'
        | 'Timer expired awaiting token refresh'
        | 'transfer failed'
        | 'Unexpected Response: 503';
}
export interface TopLevel {
    /**
     * The name by which this participant should be known.
     */
    display_name: string;
    /**
     * An optional call tag to assign to this participant.
     */
    call_tag?: string;
    chosen_idp?: string;
    sso_token?: string;
    /**
     * `<FQDN/address that the client is connecting to>`. This helps with some SAML SSO flows.
     */
    node?: string;
    /**
     * Alias of the target conference
     */
    conference_extension?: string;
    /**
     * One time token for joining a call
     */
    token?: string;
    direct_media?: boolean;
}

import {
    validateBody,
    validate200,
    validate403,
    validate404,
    validate415,
    validate502,
    validate504,
    validate529,
} from './requestTokenValidation';
type RequestTokenResponse =
    | {status: 200; data: RequestTokenMap['200']}
    | {status: 403; data: RequestTokenMap['403']}
    | {status: 404; data: RequestTokenMap['404']}
    | {status: 415; data: RequestTokenMap['415']}
    | {status: 502; data: RequestTokenMap['502']}
    | {status: 504; data: RequestTokenMap['504']}
    | {status: 529; data: RequestTokenMap['529']};
interface RequestTokenParams {
    conferenceAlias: string;
}
export async function requestToken({
    fetcher,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    params: RequestTokenParams;
    body: RequestTokenMap['Body'];
    host: string;
}): Promise<RequestTokenResponse> {
    if (!validateBody(body)) {
        throw new Error(JSON.stringify(validateBody.errors));
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${params.conferenceAlias}/request_token`,
        {
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    if (res.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate200(data)) {
            throw new Error(JSON.stringify(validate200.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate403(data)) {
            throw new Error(JSON.stringify(validate403.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 404) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate404(data)) {
            throw new Error(JSON.stringify(validate404.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 415) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Next line is validation
        const data = await res.json();
        if (!validate415(data)) {
            throw new Error(JSON.stringify(validate415.errors));
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Already validated
        return {status: res.status, data};
    }
    if (res.status === 502) {
        const data = await res.text();
        if (!validate502(data)) {
            throw new Error(JSON.stringify(validate502.errors));
        }

        return {status: res.status, data};
    }
    if (res.status === 504) {
        const data = await res.text();
        if (!validate504(data)) {
            throw new Error(JSON.stringify(validate504.errors));
        }

        return {status: res.status, data};
    }
    if (res.status === 529) {
        const data = await res.text();
        if (!validate529(data)) {
            throw new Error(JSON.stringify(validate529.errors));
        }

        return {status: res.status, data};
    } else {
        throw new Error(`Unexpected status ${res.status}`);
    }
}
