import {useEffect} from 'react';
import {captureException} from '@sentry/hub';

import type {MediaController} from '@pexip/media';
import {
    isInitial,
    isRejected,
    isInitialPermissionsNotGranted,
} from '@pexip/media';
import type {Signal} from '@pexip/signal';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {logger} from '../logger';

export const useMedia = (
    mediaService: MediaController,
    devicesChangedSubscribe: Signal<MediaDeviceInfoLike[]>['add'],
) => {
    useEffect(() => {
        mediaService.tryAndGetUserMedia({audio: true, video: true});
        const remove = devicesChangedSubscribe(() => {
            if (
                !isInitialPermissionsNotGranted(mediaService.media.status) &&
                !isInitial(mediaService.media.status) &&
                !isRejected(mediaService.media.status)
            ) {
                mediaService.getUserMedia({audio: true, video: true});
            }
        });

        return () => {
            logger.debug('Media cleanup');
            remove();
            mediaService.media.release().catch((reason: unknown) => {
                captureException(reason);
                logger.error({reason}, `GUM cleanup failed`);
            });
        };
    }, [devicesChangedSubscribe, mediaService]);
};
