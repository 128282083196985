import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import type {NotificationToastMessage} from '@pexip/components';
import {notificationToastSignal} from '@pexip/components';
import type {Signal} from '@pexip/signal';
import type {Logger} from '@pexip/logger';

import {TestId} from '../../test/testIds';
import {MicrophoneMuteMessage} from '../views/MicrophoneMuteMessage/MicrophoneMuteMessage.view';

export const createPushToTalkMuteToastHook =
    (pushToTalkSignal: Signal<boolean>, logger: Logger) => () => {
        const {t} = useTranslation();

        useEffect(
            () =>
                pushToTalkSignal.add(shouldUnmute => {
                    logger.info(
                        `PTT ${shouldUnmute ? 'unmuted' : 'muted'} call`,
                    );

                    const message: NotificationToastMessage = {
                        message: (
                            <MicrophoneMuteMessage
                                text={t(
                                    'media.audio.you-are-temporarily-unmuted',
                                    'You’re temporarily unmuted',
                                )}
                            />
                        ),
                        testid: TestId.ToastYouAreTemporarilyUnmuted,
                        position: 'bottomCenter',
                        timeout: 0,
                        isInterrupt: true,
                    };

                    if (shouldUnmute) {
                        notificationToastSignal.emit([message]);
                    } else {
                        notificationToastSignal.emit({
                            close: true,
                        });
                    }
                }),
            [t],
        );
    };
