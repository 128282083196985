import React from 'react';
import cx from 'classnames';

import type {IconSource} from '@pexip/components';
import {Button, Icon, IconTypes} from '@pexip/components';

import styles from './SelfviewButton.module.scss';

export const SelfviewButton: React.FC<
    React.PropsWithChildren<{
        ariaLabel?: string;
        className?: string;
        hasError?: boolean;
        isButtonOn?: boolean;
        iconClassName?: string;
        isDisabled?: boolean;
        isMobileDevice?: boolean;
        onClick?: () => void;
        onPointerDownCapture?: (
            e: React.MouseEvent<Element, MouseEvent>,
        ) => void;
        source?: IconSource;
    }>
> = ({
    ariaLabel,
    className,
    children,
    hasError,
    isButtonOn,
    iconClassName,
    isDisabled,
    isMobileDevice,
    onClick,
    onPointerDownCapture,
    source,
    ...props
}) => {
    const variant = hasError
        ? 'danger'
        : isButtonOn
        ? 'primary'
        : 'translucent';

    return (
        <Button
            modifier="square"
            size="compact"
            variant={variant}
            className={cx(isMobileDevice && styles.miniButton, className)}
            aria-label={ariaLabel}
            disabled={isDisabled || false}
            onClick={onClick}
            onPointerDownCapture={onPointerDownCapture}
            noHover={isMobileDevice || isDisabled || true}
            {...props}
        >
            {children || (
                <Icon
                    source={source || IconTypes.IconAdd}
                    size={isMobileDevice ? 'mini' : 'compact'}
                    className={iconClassName}
                />
            )}
        </Button>
    );
};

export type SelfviewButtonProps = React.ComponentProps<typeof SelfviewButton>;
