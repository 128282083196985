export enum TestId {
    ButtonModalClose = 'button-modal-close',
    CheckboxCheck = 'checkbox-check',
    ConfirmableMultiselectConfirm = 'confirmable-multiselect-confirm',
    HeaderCore = 'header-core',
    HeaderCoreEnhancers = 'header-core-enhancers',
    HeaderCoreDivider = 'header-core-divider',
    LineStep = 'line-step',
    InputClearButton = 'input-clear-button',
    ModalMask = 'modal-mask',
    MultiselectView = 'multiselect-view',
    MultiselectItem = 'multiselect-item',
    MultistepIndicator = 'multistep-indicator',
    MultistepIndicatorButton = 'multistep-indicator-button',
    NotificationTooltipInner = 'notification-tooltip-inner',
    NotificationTooltipClose = 'notification-tooltip-close',
    NotificationTooltipHeader = 'notification-tooltip-header',
    NotificationTooltipText = 'notification-tooltip-text',
    NotificationTooltipCta = 'notification-tooltip-cta',
    PostMeetingHeader = 'PostMeetingHeader',
    SectionAccordionDetails = 'section-accordion-details',
    SectionAccordionSummary = 'section-accordion-summary',
    SelectIcon = 'select-icon',
    SelectChevron = 'select-chevron',
    SelectView = 'select-view',
    StepNumber = 'step-number',
    StepNumberString = 'step-number-string',
    StepNumberCheckmark = 'step-number-checkmark',
    TooltipInner = 'tooltip-inner',
    UserView = 'user-view',

    //AboutModal
    PrivacyPolicyLink = 'privacy-policy-link',
    TermsOfServiceLink = 'terms-of-service-link',
    Version = 'version',
}
