import type {Url, BuildUrlOptions} from '@pexip/router';
import {createRouter, createRouterHooks} from '@pexip/router';

const baseUri = new URL(document.baseURI).pathname;

export const router = createRouter({baseUri});

export const {useRouter, createRoutesHook, createSubRoutesHook} =
    createRouterHooks(router);

export const push = (
    url: Url | string,
    {base = baseUri, ...rest}: BuildUrlOptions = {},
) => router.history.push(url, {base, ...rest});

export const replace = (
    url: Url | string,
    {base = baseUri, ...rest}: BuildUrlOptions = {},
) => router.history.replace(url, {base, ...rest});
