import React from 'react';
import cx from 'classnames';

import type {Quality} from '@pexip/peer-connection-stats';

import type {SelfviewProps} from '../Selfview.view';
import type {AutoHideButtonCallbacks} from '../../../types';
import {AspectRatioSelfview} from '../AspectRatioSelfview';

import {InMeetingSelfviewHeader} from './InMeetingSelfviewHeader.view';
import {InMeetingSelfviewFooter} from './InMeetingSelfviewFooter.view';

import styles from './InMeetingSelfview.module.scss';

export const InMeetingSelfview: React.FC<
    SelfviewProps & {
        autoHideProps: AutoHideButtonCallbacks;
        quality: Quality;
        callQualityPosition: 'topRight' | 'bottomRight';
        overlayClassName?: string;
        isAudioInputMuted?: boolean;
        isAudioInputMissing?: boolean;
        isMobileDevice?: boolean;
        onCollapseSelfview: () => void;
        onCallQualityClick: () => void;
        onToggleAudioClick: () => void;
        toggleFacingMode?: () => void;
    }
> = ({
    autoHideProps,
    overlayClassName,
    isAudioInputMuted,
    isAudioInputMissing,
    isMobileDevice,
    quality,
    onCollapseSelfview,
    onCallQualityClick,
    onToggleAudioClick,
    toggleFacingMode,
    ...props
}) => (
    <AspectRatioSelfview
        isMobileDevice={isMobileDevice}
        aspectRatioType="in-meeting"
        overlayClassName={cx(styles.selfviewOverlay, overlayClassName)}
        {...props}
    >
        <InMeetingSelfviewHeader
            onClick={onCollapseSelfview}
            isMobileDevice={isMobileDevice}
            autoHideProps={autoHideProps}
        />
        <InMeetingSelfviewFooter
            autoHideProps={autoHideProps}
            isAudioInputMuted={isAudioInputMuted}
            isAudioInputMissing={isAudioInputMissing}
            isMobileDevice={isMobileDevice}
            quality={quality}
            onCallQualityClick={onCallQualityClick}
            onToggleAudioClick={onToggleAudioClick}
            toggleFacingMode={toggleFacingMode}
        />
    </AspectRatioSelfview>
);

export type InMeetingSelfviewProps = React.ComponentProps<
    typeof InMeetingSelfview
>;
