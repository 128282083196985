import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';

import styles from './SvgImage.module.scss';

export const SvgImage: React.FC<
    React.ComponentProps<'svg'> & {
        colorScheme?: ColorScheme;
        source: React.ElementType;
    }
> = ({source: Svg, className, colorScheme, ...props}) => (
    <Svg
        className={cx(colorScheme && styles[colorScheme], className)}
        {...props}
    />
);

export type SvgImageProps = React.ComponentProps<typeof SvgImage>;
