import React, {useMemo} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {IconSource} from '@pexip/components';
import {Select} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {findDevice, findDeviceWithDeviceId} from '@pexip/media-control';

import {
    normalizeDeviceOptions,
    buildDevicePermissionRejected,
} from '../../utils/devicesSelection';

export const DeviceSelect: React.FC<
    {
        devices: MediaDeviceInfoLike[];
        iconType?: IconSource;
        isDisabled: boolean;
        label: string;
        mediaDeviceInfoLike?: MediaDeviceInfoLike;
        onDeviceChange: (device: MediaDeviceInfoLike) => void;
    } & Partial<React.ComponentProps<typeof Select>>
> = ({
    devices,
    iconType,
    isDisabled = false,
    label,
    mediaDeviceInfoLike,
    onDeviceChange,
    className,
    sizeModifier = 'small',
    ...props
}) => {
    const {t} = useTranslation();
    const normalizedOptions = useMemo(() => {
        const normalizedOptions = normalizeDeviceOptions(
            devices as MediaDeviceInfo[],
        );
        return buildDevicePermissionRejected(
            normalizedOptions,
            t('media.waiting-for-permissions', 'Waiting for permissions...'),
        );
    }, [devices, t]);

    const deviceId = useMemo(
        () =>
            mediaDeviceInfoLike?.deviceId &&
            findDevice(mediaDeviceInfoLike)(devices)
                ? mediaDeviceInfoLike.deviceId
                : '',
        [devices, mediaDeviceInfoLike],
    );

    return (
        <Select
            value={deviceId}
            onValueChange={id =>
                onDeviceChange(
                    findDeviceWithDeviceId(devices, id) as MediaDeviceInfo,
                )
            }
            options={normalizedOptions}
            iconType={iconType}
            label={label}
            labelModifier="hidden"
            className={cx('mb-1', className)}
            isFullWidth
            isDisabled={isDisabled}
            sizeModifier={sizeModifier}
            {...props}
        />
    );
};
