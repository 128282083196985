import React from 'react';
import cx from 'classnames';

import {Text} from '../Text/Text';
import type {ColorScheme} from '../../../types/variants';
import type {TooltipPosition} from '../../../types/propTypes';
import {Box} from '../Box/Box';
import {ThemeProvider} from '../../../themes/ThemeContext';

import {useTooltip} from './useTooltip';

import styles from './Tooltip.module.scss';

export const Tooltip: React.FC<
    React.ComponentProps<'div'> & {
        position?: TooltipPosition;
        text: React.ReactNode;
        colorScheme?: ColorScheme;
        tooltipContainerClassName?: string;
    }
> = ({
    className,
    children,
    colorScheme = 'light',
    position = 'top',
    text,
    tooltipContainerClassName,
    ...props
}) => {
    const {isVisible, setVisible} = useTooltip();

    return (
        <div
            className={cx(styles.tooltipContainer, tooltipContainerClassName)}
            onPointerEnter={() => setVisible({isVisible: true})}
            onPointerLeave={() => setVisible({isVisible: false})}
            {...props}
        >
            {children}
            {isVisible && (
                <ThemeProvider colorScheme={colorScheme}>
                    <Box
                        className={cx(
                            styles.tooltip,
                            styles.tooltipControl,
                            styles[position],
                            className,
                        )}
                        data-testid="tooltip-inner"
                    >
                        {typeof text === 'string' ? <Text>{text}</Text> : text}
                    </Box>
                </ThemeProvider>
            )}
        </div>
    );
};

export type TooltipProps = React.ComponentProps<typeof Tooltip>;
