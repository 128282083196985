import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {VideoWrapper, Spinner, Video} from '@pexip/components';
import {isMobileDevice} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';
import {StreamStatus} from '../../types';

import styles from './MeetingVideo.module.scss';

export const MeetingVideo: React.FC<{
    mediaStream?: MediaStream;
    selectedAudioOutputDeviceId?: string;
    className?: string;
    isPresentation?: boolean;
    status: StreamStatus;
    isFullHeight?: boolean;
    isFullWidth?: boolean;
    handleVideoClick?: () => void;
    onVideoPlaying?: () => void;
    onPictureInPictureChange?: (isPip: boolean) => void;
    onDoubleClick?: () => void;
    isContainerWide?: boolean;
}> = ({
    mediaStream,
    selectedAudioOutputDeviceId,
    isPresentation = false,
    status,
    isFullHeight,
    isFullWidth,
    handleVideoClick,
    onVideoPlaying,
    onPictureInPictureChange,
    onDoubleClick,
    isContainerWide = false,
    ...props
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [wrapperTestId, setWrapperTestId] = useState<string>();

    const isExpanded = status === StreamStatus.Expanded;
    const isExternal = status === StreamStatus.External;
    const isPip = status === StreamStatus.Pip;

    useEffect(() => {
        if (isPresentation) {
            setWrapperTestId(
                isPip ? TestId.PresentationPip : TestId.PresentationFull,
            );
        } else {
            setWrapperTestId(
                isPip ? TestId.MeetingVideoPip : TestId.MeetingVideoFull,
            );
        }
    }, [isPip, isPresentation]);

    const {t} = useTranslation();

    return (
        <VideoWrapper
            className={cx(styles.videoWrapper, {
                [styles.centeredInWideContainer]: isContainerWide,
                [styles.expanded]: isExpanded,
            })}
            isFullHeight={isFullHeight}
            isFullWidth={isFullWidth}
            borderRadius={isExternal ? 'none' : 'box'}
            onDoubleClick={onDoubleClick}
            background={isExternal ? 'black' : 'none'}
            flexDirection="row"
            data-testid={wrapperTestId}
            {...props}
        >
            <Video
                srcObject={mediaStream}
                onPlaying={() => {
                    setIsPlaying(() => {
                        onVideoPlaying?.();
                        return true;
                    });
                }}
                onPictureInPictureChange={onPictureInPictureChange}
                onClick={handleVideoClick}
                disablePictureInPicture={isMobileDevice()}
                sinkId={
                    isPresentation ? undefined : selectedAudioOutputDeviceId
                }
                muted={isPresentation}
                data-testid={
                    isPresentation
                        ? TestId.VideoPresentation
                        : TestId.VideoMeeting
                }
                aria-label={
                    isPresentation
                        ? t(
                              'meeting.video-label-presentation',
                              'Live video of another participant’s presentation',
                          )
                        : t('meeting.video-label', 'Live video of the meeting')
                }
            />

            {(!mediaStream || !isPlaying) && (
                <Spinner
                    className={styles.spinner}
                    colorScheme="dark"
                    sizeModifier={isPip ? 'small' : 'medium'}
                />
            )}
        </VideoWrapper>
    );
};

export type MeetingVideoProps = React.ComponentProps<typeof MeetingVideo>;
