import React from 'react';
import cx from 'classnames';

import type {ButtonProps, IconSource, TooltipPosition} from '@pexip/components';
import {Button, Icon, Tooltip} from '@pexip/components';

import {stopMouseEventPropagation} from '../../utils/domEventsHandles';

import styles from './Selfview.module.scss';

export const FoldedSelfviewButton: React.FC<
    ButtonProps & {
        icon: IconSource;
        iconClassName?: string;
        isMobileDevice?: boolean;
        onClick?: () => void;
        tooltipPosition?: TooltipPosition;
        tooltipText?: string;
        tooltipClassName?: string;
        tooltipContainerClassName?: string;
    }
> = ({
    icon,
    iconClassName,
    isDisabled,
    isMobileDevice,
    onClick,
    tooltipPosition = 'bottom',
    tooltipText,
    variant = 'transparent',
    tooltipClassName = styles.selfviewBtnTooltip,
    tooltipContainerClassName = styles.selfviewBtnTooltipContainer,
    className,
    ...props
}) => {
    const button = (
        <Button
            onClick={onClick}
            className={cx(
                styles.foldedSelfviewButton,
                {
                    [styles.disabled]: isDisabled,
                    [styles.miniButton]: isMobileDevice,
                },
                className,
            )}
            variant={variant}
            modifier="square"
            {...props}
        >
            <Icon
                source={icon}
                className={cx(isDisabled && styles.disabledIcon, iconClassName)}
                size={isMobileDevice ? 'compact' : 'small'}
            />
        </Button>
    );

    if (isDisabled || isMobileDevice || !tooltipText) {
        return button;
    }

    return (
        <Tooltip
            text={tooltipText}
            position={tooltipPosition}
            onPointerDownCapture={stopMouseEventPropagation(
                onClick !== undefined,
            )}
            className={tooltipClassName}
            tooltipContainerClassName={tooltipContainerClassName}
        >
            {button}
        </Tooltip>
    );
};
