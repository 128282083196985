import {
    createMedia,
    createAudioStreamProcess,
    createVideoStreamProcess,
    createAudioMixingProcess,
} from '@pexip/media';
import type {MediaProcessor, Segmenters} from '@pexip/media';
import {denoiseWasm} from '@pexip/denoise/urls';
import type {RenderEffects} from '@pexip/media-processor';
import {
    urls as mpUrls,
    createMediapipeSegmenter,
    createCanvasTransform,
} from '@pexip/media-processor';
import {isEmpty} from '@pexip/utils';
import {
    createPreviewAudioInputHook,
    createPreviewAudioOutputHook,
    createPreviewControllerHook,
    createPreviewHook,
    createPreviewVideoInputHook,
    generateMediaSignalHooks,
    qualityToMediaConstraints,
    StreamQuality,
} from '@pexip/media-components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {config, shouldEnableVideoProcessing} from '../config';
import {mediaSignals} from '../signals/Media.signals';
import {logger} from '../logger';
import {
    FFT_SIZE,
    UPDATE_FREQUENCY_HZ,
    SILENT_DETECTION_DURATION_S,
    VAD_THROTTLE_MS,
    PROCESSING_WIDTH,
    PROCESSING_HEIGHT,
    SETTINGS_PROCESSING_WIDTH,
    SETTINGS_PROCESSING_HEIGHT,
    FRAME_RATE,
    BACKGROUND_BLUR_AMOUNT,
    FOREGROUND_THRESHOLD,
    EDGE_BLUR_AMOUNT,
    RENDER_EFFECTS,
    BG_IMAGE_URL,
    SELFIE_JS_FILE_NAME,
} from '../constants';

const audioProcessor = createAudioStreamProcess({
    shouldEnable: () => config.get('audioProcessing'),
    denoiseParams: {
        wasmURL: denoiseWasm.href,
        workletModule: mpUrls.denoise().href,
    },
    fftSize: FFT_SIZE,
    analyzerUpdateFrequency: UPDATE_FREQUENCY_HZ,
    audioSignalDetectionDuration: SILENT_DETECTION_DURATION_S,
    throttleMs: VAD_THROTTLE_MS,
    onAudioSignalDetected: mediaSignals.onSilentDetected.emit,
    onVoiceActivityDetected: mediaSignals.onVAD.emit,
});

const selfieJs = new URL(
    `./assets/selfie_segmentation/${SELFIE_JS_FILE_NAME}`,
    document.baseURI,
);

const basePath = selfieJs.pathname.replace(SELFIE_JS_FILE_NAME, '');

const mediapipeSegmenter = createMediapipeSegmenter(basePath, {
    modelType: 'general',
    gluePath: selfieJs.href,
    processingWidth: PROCESSING_WIDTH,
    processingHeight: PROCESSING_HEIGHT,
});

export const segmenters: Segmenters = {
    mediapipeSelfie: mediapipeSegmenter,
};

const videoProcessor =
    shouldEnableVideoProcessing() &&
    createVideoStreamProcess({
        shouldEnable: () => config.get('videoProcessing'),
        onError: error => {
            logger.error({error}, 'Failed to process video');
        },
        segmenters,
        processingWidth: PROCESSING_WIDTH,
        processingHeight: PROCESSING_HEIGHT,
        frameRate: FRAME_RATE,
        backgroundBlurAmount: BACKGROUND_BLUR_AMOUNT,
        foregroundThreshold: FOREGROUND_THRESHOLD,
        edgeBlurAmount: EDGE_BLUR_AMOUNT,
        videoSegmentation: RENDER_EFFECTS,
        bgImageUrl: BG_IMAGE_URL,
    });

let currentDisplayMedia: MediaStream | undefined;
const getCurrentDisplayMedia = () => currentDisplayMedia;
export const setCurrentDisplayMedia = (newDisplayMedia?: MediaStream) => {
    currentDisplayMedia = newDisplayMedia;
    void mediaService.media.applyConstraints({
        audio: {mixWithAdditionalMedia: true},
    });
};
const presentationMixer = createAudioMixingProcess(getCurrentDisplayMedia);

const isMediaProcessor = (t: false | MediaProcessor): t is MediaProcessor => {
    if (!t) {
        return false;
    }
    return true;
};
const mediaProcessors = [
    videoProcessor,
    audioProcessor,
    presentationMixer,
].filter(isMediaProcessor);

const getFacingMode = (isUserFacing: boolean) =>
    isUserFacing ? 'user' : 'environment';

const getDefaultConstraints = () => ({
    audio: {
        sampleRate: 48000,
        echoCancellation: true,
        denoise: config.get('denoise'),
        noiseSuppression: !config.get('denoise'),
        vad: config.get('vad'),
        asd: config.get('asd'),
        ...(isEmpty(config.get('audioInput'))
            ? {}
            : {device: config.get('audioInput')}),
    },
    video: {
        ...qualityToMediaConstraints(getStreamQuality()),
        foregroundThreshold: config.get('foregroundThreshold'),
        backgroundBlurAmount: config.get('backgroundBlurAmount'),
        edgeBlurAmount: config.get('edgeBlurAmount'),
        flipHorizontal: config.get('flipHorizontal'),
        frameRate: config.get('frameRate'),
        videoSegmentation: config.get('segmentationEffects'),
        videoSegmentationModel: config.get('segmentationModel'),
        bgImageUrl: config.get('bgImageUrl'),
        facingMode: getFacingMode(config.get('isUserFacing')),
        ...(isEmpty(config.get('videoInput'))
            ? {}
            : {device: config.get('videoInput')}),
    },
});

export const mediaService = createMedia({
    getMuteState: () => ({
        audio: config.get('isAudioInputMuted'),
        video: config.get('isVideoInputMuted'),
    }),
    signals: mediaSignals,
    mediaProcessors,
    getDefaultConstraints,
});

config.subscribe('isAudioInputMuted', isMuted =>
    mediaService.media.muteAudio(isMuted),
);
config.subscribe('isVideoInputMuted', isMuted =>
    mediaService.media.muteVideo(isMuted),
);
config.subscribe('backgroundBlurAmount', backgroundBlurAmount => {
    void mediaService.media.applyConstraints({video: {backgroundBlurAmount}});
});
config.subscribe('foregroundThreshold', foregroundThreshold => {
    void mediaService.media.applyConstraints({video: {foregroundThreshold}});
});
config.subscribe('edgeBlurAmount', edgeBlurAmount => {
    void mediaService.media.applyConstraints({video: {edgeBlurAmount}});
});
config.subscribe('segmentationModel', videoSegmentationModel => {
    void mediaService.media.applyConstraints({video: {videoSegmentationModel}});
});
config.subscribe('bgImageUrl', bgImageUrl => {
    void mediaService.media.applyConstraints({
        video: {bgImageUrl},
    });
});
config.subscribe('denoise', denoise => {
    void mediaService.media.applyConstraints({
        audio: {denoise, noiseSuppression: !denoise},
    });
});
config.subscribe('vad', vad => {
    void mediaService.media.applyConstraints({
        audio: {vad},
    });
});
config.subscribe('asd', asd => {
    void mediaService.media.applyConstraints({
        audio: {asd},
    });
});
config.subscribe('frameRate', frameRate => {
    void mediaService.media.applyConstraints({
        video: {frameRate},
    });
});

export const {useDevices, useLocalMedia, useStreamStatus} =
    generateMediaSignalHooks({
        useDevices: {
            initial: () => mediaService.devices,
            subscribe: mediaSignals.onDevicesChanged.add,
        },

        useLocalMedia: {
            initial: () => mediaService.media,
            subscribe: mediaSignals.onMediaChanged.add,
        },

        useStreamStatus: {
            initial: () => mediaService.media.status,
            subscribe: mediaSignals.onStatusChanged.add,
        },
    });

export const usePreviewController = createPreviewControllerHook(() => {
    const renderParams = {
        width: SETTINGS_PROCESSING_WIDTH,
        height: SETTINGS_PROCESSING_HEIGHT,
        effects: config.get('segmentationEffects'),
        frameRate: config.get('frameRate'),
        backgroundBlurAmount: config.get('backgroundBlurAmount'),
        foregroundThreshold: config.get('foregroundThreshold'),
        edgeBlurAmount: config.get('edgeBlurAmount'),
        bgImageUrl: config.get('bgImageUrl'),
    };
    return {
        getCurrentDevices: () => mediaService.devices,
        getCurrentMedia: () => mediaService.media,
        updateMainStream: mediaService.getUserMediaAsync,
        mediaSignal: mediaSignals.onMediaChanged,
        processors: [
            shouldEnableVideoProcessing() &&
                createVideoStreamProcess({
                    shouldEnable: () => config.get('videoProcessing'),
                    onError: error => {
                        logger.error({error}, 'Failed to process video');
                    },
                    scope: 'PreviewStreamController',
                    videoSegmentationModel: config.get('segmentationModel'),
                    segmenters,
                    transformer: createCanvasTransform(
                        segmenters[config.get('segmentationModel')],
                        {
                            selfManageSegmenter: true,
                            ...renderParams,
                        },
                    ),
                    ...renderParams,
                    processingWidth: renderParams.width,
                    processingHeight: renderParams.height,
                    videoSegmentation: renderParams.effects,
                }),
        ].filter(isMediaProcessor),
    };
});

const getSortedBandwidths = (): string[] =>
    [...config.get('bandwidths')].sort((a, b) => Number(a) - Number(b));

export const getStreamQuality = () => {
    const bandwidth = config.get('bandwidth');
    const [low, medium, high, veryHigh] = getSortedBandwidths();

    switch (bandwidth) {
        case low:
            return StreamQuality.Low;
        case medium:
            return StreamQuality.Medium;
        case high:
            return StreamQuality.High;
        case veryHigh:
            return StreamQuality.VeryHigh;
        default:
            return StreamQuality.Auto;
    }
};

export const usePreviewStreamQuality = createPreviewHook({
    get: getStreamQuality,
    set: (streamQuality: StreamQuality) => {
        let value;
        const [low, medium, high, veryHigh] = getSortedBandwidths();

        switch (streamQuality) {
            case StreamQuality.Low:
                value = low;
                break;
            case StreamQuality.Medium:
                value = medium;
                break;
            case StreamQuality.High:
                value = high;
                break;
            case StreamQuality.VeryHigh:
                value = veryHigh;
                break;
            default:
                value = '';
                break;
        }

        if (typeof value === 'string') {
            config.set({key: 'bandwidth', value, persist: true});
        }
    },
});

export const usePreviewDenoise = createPreviewHook({
    get: () => config.get('denoise'),
    set: (value: boolean) => config.set({key: 'denoise', value, persist: true}),
});

export const usePreviewSegmentationEffects = createPreviewHook({
    get: () => config.get('segmentationEffects'),
    set: (value: RenderEffects) =>
        config.set({key: 'segmentationEffects', value, persist: true}),
});

export const usePreviewAudioOutput = createPreviewAudioOutputHook(
    (value: MediaDeviceInfoLike) => config.set({key: 'audioOutput', value}),
);

export const usePreviewAudioInput = createPreviewAudioInputHook({
    get: () => config.get('audioInput'),
    getExpected: () => mediaService.media.expectedAudioInput,
    set: (value?: MediaDeviceInfoLike) =>
        config.set({key: 'audioInput', value, persist: true}),
});

export const usePreviewVideoInput = createPreviewVideoInputHook({
    get: () => config.get('videoInput'),
    getExpected: () => mediaService.media.expectedVideoInput,
    set: (value?: MediaDeviceInfoLike) =>
        config.set({key: 'videoInput', value, persist: true}),
});

export const enableAudioSignalDetection = () =>
    config.set({key: 'asd', value: true});

export const disableAudioSignalDetection = () =>
    config.set({key: 'asd', value: false});

export const toggleFacingMode = () => {
    // FIXME: you probably shouldnt set it in the first place but we do in express flow on mobile
    config.set({
        key: 'videoInput',
        value: undefined,
    });
    const isUserFacing = !config.get('isUserFacing');
    config.set({
        key: 'isUserFacing',
        value: isUserFacing,
        persist: true,
    });
    void mediaService.getUserMedia({
        audio: true,
        video: {
            facingMode: {
                ideal: getFacingMode(isUserFacing),
            },
        },
    });
};
