import {
    UserMediaStatus,
    isGrantedOnlyAudio,
    isGrantedOnlyVideo,
    areBothGranted,
    isRejected,
} from '@pexip/media';

export const isVideoActive = (
    isVideoInputMuted: boolean,
    mediaStream?: MediaStream,
) =>
    !isVideoInputMuted &&
    mediaStream &&
    mediaStream?.getVideoTracks().length > 0;

export const isVideoMuted = (
    isVideoInputMuted: boolean,
    streamStatus?: UserMediaStatus,
) => {
    const grantedVideo =
        !!streamStatus &&
        (areBothGranted(streamStatus) || isGrantedOnlyVideo(streamStatus));
    const hasVideoAndMuted = isVideoInputMuted && grantedVideo;

    return (
        hasVideoAndMuted ||
        streamStatus !== UserMediaStatus.InitialPermissionsNotGranted
    );
};

export const shouldShowErrorBorder = (streamStatus?: UserMediaStatus) =>
    !!streamStatus &&
    (isRejected(streamStatus) ||
        isGrantedOnlyAudio(streamStatus) ||
        isGrantedOnlyVideo(streamStatus));
